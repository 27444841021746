import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

export const launchesAdapter = createEntityAdapter({
  selectId: (launch) => launch._id,
});

export const launchesInitialState = launchesAdapter.getInitialState({
  favourites: [],
});

const launchesSlice = createSlice({
  name: "launches",
  initialState: launchesInitialState,
  reducers: {
    launchesChunkReceived: launchesAdapter.upsertMany,
    addFavourite: (state, { payload }) => {
      if (!state.favourites.includes(payload)) {
        state.favourites.push(payload);
      }
    },
    removeFavourite: (state, { payload }) => {
      state.favourites = state.favourites.filter((item) => item !== payload);
    },
  },
});

export const {
  launchesChunkReceived,
  addFavourite,
  removeFavourite,
} = launchesSlice.actions;

export default launchesSlice.reducer;

export const selectIsLaunchFavourite = (state, launchId) =>
  state.launches.favourites.includes(launchId);

export const selectFavouriteLaunchesTotal = (state) =>
  state.launches.favourites.length;

export const selectFavouriteLaunches = (state) => state.launches.favourites;

export const { selectById: selectLaunchById } = launchesAdapter.getSelectors(
  (state) => state.launches
);
