import { combineReducers } from "@reduxjs/toolkit";

import launchesSlice from "./slices/launches";
import launchPadsSlice from "./slices/launchPads";

const rootReducer = combineReducers({
  launches: launchesSlice,
  launchPads: launchPadsSlice,
});

export default rootReducer;
