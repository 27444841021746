import useSWR from "swr";

const defaultFetcher = (...args) => fetch(...args).then((res) => res.json());

export const apiVersion = { 3: "3", 4: "4" };

export function useSpaceX(
  path,
  options,
  version = apiVersion["3"],
  fetcher = defaultFetcher
) {
  const searchParams = new URLSearchParams();
  for (const property in options) {
    searchParams.append(property, options[property]);
  }

  const apiVersionUrls = {
    3: process.env.REACT_APP_SPACEX_API_URL,
    4: process.env.REACT_APP_SPACEX_4_API_URL,
  };
  const spacexApiBase = apiVersionUrls[version];
  const endpointUrl = `${spacexApiBase}${path}?${searchParams.toString()}`;

  return useSWR(path ? endpointUrl : null, fetcher);
}
