import React from "react";
import {
  Flex,
  Text,
  IconButton,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
} from "@chakra-ui/core";
import { Menu } from "react-feather";

import FavouritesMenu from "./favourites-menu";

export default function NavBar() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        px="6"
        py="5"
        bg="gray.800"
        color="white"
        pos="sticky"
        top="0px"
        zIndex="1"
      >
        <Text
          fontFamily="mono"
          letterSpacing="2px"
          fontWeight="bold"
          fontSize="lg"
        >
          ¡SPACE·R0CKETS!
        </Text>
        <IconButton
          isRound
          onClick={onOpen}
          size="sm"
          variant="ghost"
          variantColor="white"
          aria-label="Open menu"
          icon={Menu}
        ></IconButton>
      </Flex>
      <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent overflow="auto">
          <FavouritesMenu />
        </DrawerContent>
      </Drawer>
    </>
  );
}
