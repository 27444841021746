import React from "react";
import { Routes, Route } from "react-router-dom";
import { Box } from "@chakra-ui/core";
import Launches from "./launches";
import Launch from "./launch";
import Home from "./home";
import LaunchPads from "./launch-pads";
import LaunchPad from "./launch-pad";
import NavBar from "./navbar";
import Satellites from "./satellites";

export default function App() {
  return (
    <Box minHeight="100vh" display="flex" flexDirection="column">
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/launches" element={<Launches />} />
        <Route path="/launches/:launchId" element={<Launch />} />
        <Route path="/launch-pads" element={<LaunchPads />} />
        <Route path="/launch-pads/:launchPadId" element={<LaunchPad />} />
        <Route path="/satellites" element={<Satellites />} />
      </Routes>
    </Box>
  );
}
