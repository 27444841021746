import React from "react";
import Breadcrumbs from "./breadcrumbs";
import { Box, Flex, Spinner, Text } from "@chakra-ui/core";
import { Viewer, Entity, Globe, EntityDescription } from "resium";
import Error from "./error";
import { useSpaceX, apiVersion } from "../utils/use-space-x";
import { Cartographic } from "cesium";
import { formatDate } from "../utils/format-date";

export default function Satellites() {
  const { data, error } = useSpaceX(
    `/starlink/query`,
    {},
    apiVersion["4"],
    (url) =>
      fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          options: {
            select:
              "longitude latitude height_km velocity_kms spaceTrack.OBJECT_NAME spaceTrack.LAUNCH_DATE",
            pagination: false,
          },
        }),
      }).then((res) => res.json())
  );

  if (error) return <Error />;
  if (!data) {
    return (
      <Flex justifyContent="center" alignItems="center" minHeight="50vh">
        <Spinner size="lg" />
      </Flex>
    );
  }

  return (
    <>
      <Breadcrumbs
        items={[{ label: "Home", to: "/" }, { label: "Starlink satellites" }]}
      />
      <Text m="6">
        Current positions of Starlink satellites. Updated hourly.
      </Text>
      <Box position="relative" flex="1">
        <Viewer
          full
          animation={false}
          sceneModePicker={false}
          timeline={false}
          navigationHelpButton={false}
          fullscreenButton={false}
          creditContainer="cesium-credits"
        >
          <Globe enableLighting />
          <EntitiesList satellites={data.docs} />
        </Viewer>
      </Box>
      <Box id="cesium-credits" display="none"></Box>
    </>
  );
}

function EntitiesList({ satellites }) {
  return satellites.map(
    ({ id, longitude, latitude, velocity_kms, height_km, spaceTrack }) => {
      const position = Cartographic.toCartesian(
        new Cartographic(longitude, latitude, height_km * 1000)
      );
      return (
        <Entity
          key={id}
          position={position}
          point={{ pixelSize: 10 }}
          name={spaceTrack.OBJECT_NAME}
        >
          <EntityDescription>
            <table cellSpacing="10px">
              <tbody>
                <tr>
                  <td>Launch date</td>
                  <td>{formatDate(spaceTrack.LAUNCH_DATE)}</td>
                </tr>
                <tr>
                  <td>Longitude</td>
                  <td>{longitude}</td>
                </tr>
                <tr>
                  <td>Latitude</td>
                  <td>{latitude}</td>
                </tr>
                <tr>
                  <td>Height, km</td>
                  <td>{height_km?.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>Velocity, km/s</td>
                  <td>{velocity_kms?.toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          </EntityDescription>
        </Entity>
      );
    }
  );
}
