import { configureStore } from "@reduxjs/toolkit";

import rootReducer from "./rootReducer";
import { launchesInitialState } from "./slices/launches";
import { launchPadsInitialState } from "./slices/launchPads";
import { persistStore, getPersistedStore } from "./persistStore";

const initialStoreState = {
  launches: launchesInitialState,
  launchPads: launchPadsInitialState,
};

const store = configureStore({
  reducer: rootReducer,
  preloadedState: getPersistedStore() || initialStoreState,
});

store.subscribe(() => persistStore(store));

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./rootReducer", () => {
    const newRootReducer = require("./rootReducer").default;
    store.replaceReducer(newRootReducer);
  });
}

export default store;

export function createTestStore(preloadedState = {}) {
  const state = { ...initialStoreState, ...preloadedState };
  return configureStore({
    reducer: rootReducer,
    preloadedState: state,
  });
}
