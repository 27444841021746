import React from "react";
import { Text, IconButton, PseudoBox, Box, Flex } from "@chakra-ui/core";
import { XCircle } from "react-feather";
import { formatDate } from "../utils/format-date";
import { selectLaunchById } from "../store/slices/launches";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Image from "./loadable-image";
import { useFavouriteLaunch } from "../utils/use-favourite-launch";

export default function FavouriteLaunchItem({ id }) {
  const launch = useSelector((state) => selectLaunchById(state, id));
  return (
    <PseudoBox
      role="group"
      overflow="visible"
      position="relative"
      _hover={{ boxShadow: "md" }}
      transition="box-shadow .2s"
      rounded="md"
    >
      <RemoveFromFavouriteButton
        position="absolute"
        top="-12px"
        right="-12px"
        launchId={id}
      />
      <Box
        as={Link}
        display="block"
        to={`/launches/${launch.flight_number.toString()}`}
        overflow="hidden"
        borderWidth="1px"
        rounded="md"
      >
        <Image
          src={
            launch.links.flickr_images[0]?.replace("_o.jpg", "_z.jpg") ??
            launch.links.mission_patch_small
          }
          alt={`${launch.mission_name} launch`}
          height={["50px", null, "100px"]}
          width="100%"
          objectFit="cover"
          objectPosition="bottom"
        />

        <Box p={4}>
          <Flex alignItems="center">
            <SuccessBadge isSuccessful={launch.launch_success} />
            <Box
              color="gray.500"
              fontWeight="semibold"
              letterSpacing="wide"
              fontSize="xs"
              textTransform="uppercase"
              ml="2"
            >
              {launch.rocket.rocket_name} &bull; {launch.launch_site.site_name}
            </Box>
          </Flex>
          <Text mt="1" fontSize="sm">
            {formatDate(launch.launch_date_utc)}{" "}
          </Text>
          <Box
            mt="2"
            fontWeight="semibold"
            as="h4"
            lineHeight="tight"
            isTruncated
            aria-label="mission name"
          >
            {launch.mission_name}
          </Box>
        </Box>
      </Box>
    </PseudoBox>
  );
}

function SuccessBadge({ isSuccessful }) {
  return isSuccessful ? (
    <Box
      background="green"
      title="Successful mission"
      width={3}
      height={3}
      rounded="full"
    />
  ) : (
    <Box
      background="red"
      title="Mission failed"
      width={3}
      height={3}
      rounded="full"
    />
  );
}

function RemoveFromFavouriteButton({ launchId, ...props }) {
  const { toggleFavourite } = useFavouriteLaunch(launchId);
  return (
    <IconButton
      isRound
      aria-label="Remove from favourite"
      size="sm"
      visibility="hidden"
      opacity="0"
      variant="solid"
      background="white"
      icon={XCircle}
      onClick={toggleFavourite}
      _groupHover={{ visibility: "visible", opacity: "1" }}
      {...props}
    />
  );
}
