import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

export const launchPadsAdapter = createEntityAdapter({
  selectId: (launchPad) => launchPad.site_id,
});

export const launchPadsInitialState = launchPadsAdapter.getInitialState({
  favourites: [],
});

const launchPadsSlice = createSlice({
  name: "launchPads",
  initialState: launchPadsInitialState,
  reducers: {
    launchPadsChunkReceived: launchPadsAdapter.upsertMany,
    addFavourite: (state, { payload }) => {
      if (!state.favourites.includes(payload)) {
        state.favourites.push(payload);
      }
    },
    removeFavourite: (state, { payload }) => {
      state.favourites = state.favourites.filter((item) => item !== payload);
    },
  },
});

export const {
  launchPadsChunkReceived,
  addFavourite,
  removeFavourite,
} = launchPadsSlice.actions;

export default launchPadsSlice.reducer;

export const selectIsLaunchPadFavourite = (state, launchId) =>
  state.launchPads.favourites.includes(launchId);

export const selectFavouriteLaunchPadsTotal = (state) =>
  state.launchPads.favourites.length;

export const selectFavouriteLaunchPads = (state) => state.launchPads.favourites;

export const {
  selectById: selectLaunchPadById,
} = launchPadsAdapter.getSelectors((state) => state.launchPads);
