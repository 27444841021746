import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import {
  selectIsLaunchPadFavourite,
  addFavourite,
  removeFavourite,
} from "../store/slices/launchPads";

export function useFavouriteLaunchPad(launchPadId) {
  const dispatch = useDispatch();
  const isFavourite = useSelector((state) =>
    selectIsLaunchPadFavourite(state, launchPadId)
  );
  const toggleFavourite = useCallback(
    (e) => {
      e.preventDefault(); // prevent link click
      e.stopPropagation(); // prevent menu close
      if (isFavourite) {
        dispatch(removeFavourite(launchPadId));
      } else {
        dispatch(addFavourite(launchPadId));
      }
    },
    [dispatch, isFavourite, launchPadId]
  );
  return { isFavourite, toggleFavourite };
}
