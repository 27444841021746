import React from "react";
import {
  Text,
  DrawerHeader,
  DrawerBody,
  List,
  Heading,
  ListItem,
} from "@chakra-ui/core";
import {
  selectFavouriteLaunchesTotal,
  selectFavouriteLaunches,
} from "../store/slices/launches";
import {
  selectFavouriteLaunchPadsTotal,
  selectFavouriteLaunchPads,
} from "../store/slices/launchPads";
import { useSelector } from "react-redux";
import FavouriteLaunchItem from "./favourite-launch-item";
import FavouriteLaunchPadItem from "./favourite-launch-pad-item";
import Collapsible from "./collapsible";

export default function FavouritesMenu() {
  const totalFavouriteLaunches = useSelector(selectFavouriteLaunchesTotal);
  const totalFavouriteLaunchPads = useSelector(selectFavouriteLaunchPadsTotal);
  const noFavourites = totalFavouriteLaunches + totalFavouriteLaunchPads === 0;

  return (
    <>
      <DrawerHeader borderBottomWidth="1px">Favourites</DrawerHeader>
      <DrawerBody aria-label="favourites list">
        {noFavourites ? (
          <Text>No favourites added yet</Text>
        ) : (
          <>
            <FavouriteLaunchesMenu total={totalFavouriteLaunches} />
            <FavouriteLaunchPadsMenu total={totalFavouriteLaunchPads} />
          </>
        )}
      </DrawerBody>
    </>
  );
}

function FavouriteLaunchesMenu({ total = 0 }) {
  if (!total) {
    return null;
  }
  const heading = (
    <Heading as="h4" size="md" id="fav-launches-heading">
      Launches ({total})
    </Heading>
  );
  return (
    <Collapsible heading={heading}>
      <FavouriteLaunchesList />
    </Collapsible>
  );
}

function FavouriteLaunchPadsMenu({ total = 0 }) {
  if (!total) {
    return null;
  }
  const heading = (
    <Heading as="h4" size="md" id="fav-launchpads-heading">
      Launch pads ({total})
    </Heading>
  );
  return (
    <Collapsible heading={heading} mt={2}>
      <FavouriteLaunchPadsList />
    </Collapsible>
  );
}

function FavouriteLaunchesList() {
  const favouriteLaunchesIds = useSelector(selectFavouriteLaunches);
  return (
    <List aria-labelledby="fav-launches-heading" spacing={3}>
      {favouriteLaunchesIds.map((id) => (
        <ListItem key={id}>
          <FavouriteLaunchItem id={id} />
        </ListItem>
      ))}
    </List>
  );
}

function FavouriteLaunchPadsList() {
  const favouriteLaunchPadsIds = useSelector(selectFavouriteLaunchPads);
  return (
    <List aria-labelledby="fav-launchpads-heading" spacing={3}>
      {favouriteLaunchPadsIds.map((id) => (
        <ListItem key={id}>
          <FavouriteLaunchPadItem id={id} />
        </ListItem>
      ))}
    </List>
  );
}
