import React from "react";
import { Box, IconButton } from "@chakra-ui/core";
import { Heart as HeartIcon } from "react-feather";

const EmptyHeart = () => <Box as={HeartIcon} color="red" />;
const FilledHeart = () => <Box as={HeartIcon} fill="currentColor" />;

export default function FavouriteButton({ isFavourite, ...props }) {
  const label = isFavourite ? "Remove from favourite" : "Add to favourite";
  return (
    <IconButton
      isRound
      size="md"
      variant="ghost"
      variantColor="red"
      aria-label={label}
      title={label}
      icon={isFavourite ? FilledHeart : EmptyHeart}
      {...props}
    />
  );
}
