import React from "react";
import { Button, Box, Collapse } from "@chakra-ui/core";
import { ChevronDown, ChevronUp } from "react-feather";

export default function Collapsible({ heading, children, ...props }) {
  const [isOpen, setIsOpen] = React.useState(true);
  const CollapseIcon = isOpen ? ChevronUp : ChevronDown;
  const onExpandCollapseClick = () => setIsOpen(!isOpen);
  return (
    <>
      <Button
        display="flex"
        width="100%"
        variant="unstyled"
        alignItems="center"
        onClick={onExpandCollapseClick}
        {...props}
      >
        {heading}
        <Box as={CollapseIcon} ml="auto" />
      </Button>
      <Collapse isOpen={isOpen} mt={2}>
        {children}
      </Collapse>
    </>
  );
}
