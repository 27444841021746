import React from "react";
import { Badge, Box, SimpleGrid, Text, Flex, ListItem } from "@chakra-ui/core";
import Image from "./loadable-image";
import { format as timeAgo } from "timeago.js";
import { Link } from "react-router-dom";
import { useSWRPages } from "swr";

import { useSpaceX } from "../utils/use-space-x";
import { formatDate } from "../utils/format-date";
import Error from "./error";
import Breadcrumbs from "./breadcrumbs";
import LoadMoreButton from "./load-more-button";
import { launchesChunkReceived } from "../store/slices/launches";
import { useDispatch } from "react-redux";
import { useFavouriteLaunch } from "../utils/use-favourite-launch";
import FavouriteButton from "./favourite-button";
const PAGE_SIZE = 12;

export default function Launches() {
  const dispatch = useDispatch();
  // uses undocumented pagination feature of SWR,
  // see https://github.com/zeit/swr/tree/master/examples/pagination
  const { pages, isLoadingMore, isReachingEnd, loadMore } = useSWRPages(
    "launches-page",
    ({ offset, withSWR }) => {
      const { data: launches, error } = withSWR(
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useSpaceX("/launches/past", {
          limit: PAGE_SIZE,
          offset,
          order: "desc",
          id: true,
          sort: "launch_date_utc",
        })
      );

      if (error) return <Error />;
      if (!launches) return null;

      dispatch(launchesChunkReceived(launches));
      return launches.map((launch) => (
        <ListItem key={launch._id} listStyleType="none">
          <LaunchItem launch={launch} />
        </ListItem>
      ));
    },
    (SWR, index) => {
      if (SWR.data && SWR.data.length === 0) return null;
      return (index + 1) * PAGE_SIZE;
    },
    []
  );
  return (
    <div>
      <Breadcrumbs
        items={[{ label: "Home", to: "/" }, { label: "Launches" }]}
      />
      <SimpleGrid
        m={[2, null, 6]}
        minChildWidth="350px"
        spacing="4"
        aria-label="launches"
        role="list"
      >
        {pages}
      </SimpleGrid>
      <LoadMoreButton
        loadMore={loadMore}
        isReachingEnd={isReachingEnd}
        isLoadingMore={isLoadingMore}
      />
    </div>
  );
}

export function LaunchItem({ launch }) {
  const { isFavourite, toggleFavourite } = useFavouriteLaunch(launch._id);

  return (
    <Box
      as={Link}
      display="block"
      to={`/launches/${launch.flight_number.toString()}`}
      boxShadow="md"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      position="relative"
    >
      <Image
        src={
          launch.links.flickr_images[0]?.replace("_o.jpg", "_z.jpg") ??
          launch.links.mission_patch_small
        }
        alt={`${launch.mission_name} launch`}
        height={["200px", null, "300px"]}
        width="100%"
        objectFit="cover"
        objectPosition="bottom"
      />

      <Image
        position="absolute"
        top="5"
        right="5"
        src={launch.links.mission_patch_small}
        height="75px"
        objectFit="contain"
        objectPosition="bottom"
      />

      <Box p="6">
        <Flex alignItems="center">
          {launch.launch_success ? (
            <Badge px="2" variant="solid" variantColor="green">
              Successful
            </Badge>
          ) : (
            <Badge px="2" variant="solid" variantColor="red">
              Failed
            </Badge>
          )}
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            ml="2"
          >
            {launch.rocket.rocket_name} &bull; {launch.launch_site.site_name}
          </Box>
          <FavouriteButton
            ml="auto"
            mr="-8px"
            isFavourite={isFavourite}
            onClick={toggleFavourite}
          />
        </Flex>

        <Box
          mt="1"
          fontWeight="semibold"
          as="h4"
          lineHeight="tight"
          isTruncated
          aria-label="mission name"
        >
          {launch.mission_name}
        </Box>
        <Flex aria-label="mission date">
          <Text fontSize="sm">{formatDate(launch.launch_date_utc)} </Text>
          <Text color="gray.500" ml="2" fontSize="sm">
            {timeAgo(launch.launch_date_utc)}
          </Text>
        </Flex>
      </Box>
    </Box>
  );
}
