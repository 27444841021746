import React from "react";
import { IconButton, PseudoBox, Box, Flex, Badge } from "@chakra-ui/core";
import { XCircle } from "react-feather";
import { selectLaunchPadById } from "../store/slices/launchPads";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useFavouriteLaunchPad } from "../utils/use-favourite-launch-pad";

export default function FavouriteLaunchPadItem({ id }) {
  const launchPad = useSelector((state) => selectLaunchPadById(state, id));
  return (
    <PseudoBox
      role="group"
      overflow="visible"
      position="relative"
      _hover={{ boxShadow: "md" }}
      transition="box-shadow .2s"
      rounded="md"
    >
      <RemoveFromFavouriteButton
        position="absolute"
        top="-12px"
        right="-12px"
        launchPadId={id}
      />
      <Box
        as={Link}
        display="block"
        to={`/launch-pads/${launchPad.site_id}`}
        overflow="hidden"
        borderWidth="1px"
        rounded="md"
        p={4}
      >
        <Box
          mb="2"
          fontWeight="semibold"
          as="h4"
          lineHeight="tight"
          isTruncated
          aria-label="mission name"
        >
          {launchPad.name}
        </Box>
        <Flex alignItems="center">
          <ActiveBadge isActive={launchPad.launch_success} />
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            ml="2"
          >
            {launchPad.vehicles_launched.join(", ")}
          </Box>
        </Flex>
      </Box>
    </PseudoBox>
  );
}

function ActiveBadge({ isActive }) {
  return isActive ? (
    <Badge variantColor="green">Active</Badge>
  ) : (
    <Badge variantColor="red">Retired</Badge>
  );
}

function RemoveFromFavouriteButton({ launchPadId, ...props }) {
  const { toggleFavourite } = useFavouriteLaunchPad(launchPadId);
  return (
    <IconButton
      isRound
      aria-label="Remove from favourite"
      size="sm"
      visibility="hidden"
      opacity="0"
      variant="solid"
      background="white"
      icon={XCircle}
      onClick={toggleFavourite}
      _groupHover={{ visibility: "visible", opacity: "1" }}
      {...props}
    />
  );
}
