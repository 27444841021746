import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import {
  selectIsLaunchFavourite,
  addFavourite,
  removeFavourite,
} from "../store/slices/launches";

export function useFavouriteLaunch(launchId) {
  const dispatch = useDispatch();
  const isFavourite = useSelector((state) =>
    selectIsLaunchFavourite(state, launchId)
  );
  const toggleFavourite = useCallback(
    (e) => {
      e.preventDefault(); // prevent link click
      e.stopPropagation(); // prevent menu close
      if (isFavourite) {
        dispatch(removeFavourite(launchId));
      } else {
        dispatch(addFavourite(launchId));
      }
    },
    [dispatch, isFavourite, launchId]
  );
  return { isFavourite, toggleFavourite };
}
