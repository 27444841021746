import React, { useState } from "react";
import { Image, Skeleton } from "@chakra-ui/core";

export default function LoadableImage({ ...props }) {
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <Skeleton isLoaded={isLoaded}>
      <Image onLoad={() => setIsLoaded(true)} loading="lazy" {...props} />
    </Skeleton>
  );
}
