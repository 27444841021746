import { DateTime as dt } from "luxon";

export function formatDate(timestamp) {
  return new Intl.DateTimeFormat("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(new Date(timestamp));
}

/**
 * Provides a human-readable date-time string from ISO timestamp.
 * @param {string} timestamp
 * @param {boolean} showLocalTime preserve local timezone (browser timezone if false)
 * @example
 * formatDateTime('2008-08-02T15:34:00+09:00', true);
 * // returns localized string like August 2, 2008, 3:34:00 PM UTC+9
 * @example
 * formatDateTime('2008-08-02T15:34:00+09:00', false);
 * // returns localized string like August 2, 2008, 10:34:00 AM GMT+4
 */
export function formatDateTime(timestamp, showLocalTime = false) {
  const dateTime = dt.fromISO(timestamp, { setZone: showLocalTime });
  const formatConfig = {
    locale: "en-US",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  if (showLocalTime) {
    const localDateTime = dateTime.toLocaleString(formatConfig);
    const localTimeZoneOffset = dateTime.toFormat("ZZZZ");
    return `${localDateTime} ${localTimeZoneOffset}`;
  }
  return dateTime.toLocaleString({ ...formatConfig, timeZoneName: "short" });
}
